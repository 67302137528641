import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { FormComponent } from './pages/form/form.component';
import { ThanksComponent } from './pages/thanks/thanks.component';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { ReadAcknowledgementComponent } from './pages/read-acknowledgement/read-acknowledgement.component';
import { DatabaseHelper } from './helpers/DatabaseHelper';
import { REGION } from '@angular/fire/compat/functions';
import { FormsModule } from '@angular/forms';
import { NotActiveComponent } from './pages/not-active/not-active.component';
import { SecretCodeComponent } from './pages/secret-code/secret-code.component';
import { HistoryComponent } from './pages/history/history.component';
import { DateSuffixPipe } from './pipes/date-suffix.pipe';
import { AngularFireStorage } from '@angular/fire/compat/storage';


@NgModule({
  declarations: [
    AppComponent,
    ReadAcknowledgementComponent,
    HomeComponent,
    FormComponent,
    ThanksComponent,
    NotActiveComponent,
    SecretCodeComponent,
    HistoryComponent,
    DateSuffixPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    FormsModule,
    provideStorage(() => getStorage()),
  ],
  providers: [
    DatabaseHelper,
    { provide: REGION, useValue: 'australia-southeast1' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
