import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
// import { timingSafeEqual } from 'crypto';
// import { map } from 'rxjs/operators';
// import { TouchSequence } from 'selenium-webdriver';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';

@Component({
  selector: 'app-read-acknowledgement',
  templateUrl: './read-acknowledgement.component.html',
  styleUrls: ['./read-acknowledgement.component.css']
})
export class ReadAcknowledgementComponent implements OnInit {

  public title: string = '';
  public reason: string = '';
  public recipient: string = '';
  public acknowledgementId: string = '';
  public noAcknowledgement:boolean = false;
  public hideButton: boolean = false;
  public users: any;
  public activeUser: any = "default";
  public selectedUser: any = "default";
  public timeToRead : boolean = false;
  public currentReader : string = "Someone else";
  public flashing : boolean = false;
  public activeUsers: any = [];
  public removeUserCount: any = 0;
  public video = document.createElement('video');
  public mainContent = document.getElementsByTagName('main-div');

  constructor(private router : Router,  private firestore: AngularFirestore, private databaseHelper: DatabaseHelper) { }
  ngOnInit(): void {
    console.log("Active user = "+this.activeUser);
    console.log("Selected user = "+this.selectedUser);
    this.loadUsers();
    this.startMonitoring();
    this.databaseHelper.systemInfo().then(val =>{
      let data : any = val.data();
      if(Date.now()  < (data.systemActive + (60*60*1*1000)))
      {

      }
      else
      {
        this.router.navigateByUrl('')
      }
    });


    this.video.setAttribute('loop', '');
    // Add some styles if needed
    this.video.setAttribute('style', 'position: fixed; width: 0px; height 0px;');
    this.video.setAttribute('playsinline',"");
    this.addSourceToVideo(this.video, 'mp4', this.base64('video/mp4', 'AAAAHGZ0eXBpc29tAAACAGlzb21pc28ybXA0MQAAAAhmcmVlAAAAG21kYXQAAAGzABAHAAABthADAowdbb9/AAAC6W1vb3YAAABsbXZoZAAAAAB8JbCAfCWwgAAAA+gAAAAAAAEAAAEAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAAAIVdHJhawAAAFx0a2hkAAAAD3wlsIB8JbCAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAIAAAACAAAAAABsW1kaWEAAAAgbWRoZAAAAAB8JbCAfCWwgAAAA+gAAAAAVcQAAAAAAC1oZGxyAAAAAAAAAAB2aWRlAAAAAAAAAAAAAAAAVmlkZW9IYW5kbGVyAAAAAVxtaW5mAAAAFHZtaGQAAAABAAAAAAAAAAAAAAAkZGluZgAAABxkcmVmAAAAAAAAAAEAAAAMdXJsIAAAAAEAAAEcc3RibAAAALhzdHNkAAAAAAAAAAEAAACobXA0dgAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAIAAgASAAAAEgAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABj//wAAAFJlc2RzAAAAAANEAAEABDwgEQAAAAADDUAAAAAABS0AAAGwAQAAAbWJEwAAAQAAAAEgAMSNiB9FAEQBFGMAAAGyTGF2YzUyLjg3LjQGAQIAAAAYc3R0cwAAAAAAAAABAAAAAQAAAAAAAAAcc3RzYwAAAAAAAAABAAAAAQAAAAEAAAABAAAAFHN0c3oAAAAAAAAAEwAAAAEAAAAUc3RjbwAAAAAAAAABAAAALAAAAGB1ZHRhAAAAWG1ldGEAAAAAAAAAIWhkbHIAAAAAAAAAAG1kaXJhcHBsAAAAAAAAAAAAAAAAK2lsc3QAAAAjqXRvbwAAABtkYXRhAAAAAQAAAABMYXZmNTIuNzguMw=='));
    document.body.appendChild(this.video);

  }

  joinSession()
  {
    this.activeUser = this.selectedUser;
    console.log("Active user = "+this.activeUser);
    console.log("Selected user = "+this.selectedUser);
    this.databaseHelper.joinCurrentReadingSession(this.activeUser).then(val => {
      this.checkIn();
      this.hideButton = false;
      this.video.play();
    });    
  }

  public async checkIn()
  {
    if(await this.databaseHelper.checkInWithUser(this.activeUser))  
    {
        console.log("User checked in with result = true");
        this.databaseHelper.fetchCurrentUser().then(val => {
          val.docs.forEach(doc => {
              var documentData = {data : doc.data(), docId : doc.id};
              console.log("Checking user = "+documentData.docId);
              if(documentData.docId === this.activeUser)
              {
                //Check for the oldest user and show them something to read.
              }
          });
      });
    }
    else
    {
      console.log("User checked in with result = false");
      //User doesn't exist in the active table so direct them back to pick themselves
      this.activeUser = "default";
      this.selectedUser = "default";
      this.timeToRead = false;
    }
  }

  public async startMonitoring()
  {
    console.log("Monitoring now!");
    this.activeUsers = this.firestore.collection("activeUsers").snapshotChanges().subscribe(result =>{
      console.log("Got result change 2");
      var users = result.map(a => {
        var data : any = a.payload.doc.data();
        var id : any = a.payload.doc.id;
        console.log("Got changes! "+id+" = "+data.dateAdded);
        return {id: id, data: data};
      });
      var oldestTime = Number.MAX_SAFE_INTEGER;
      var currentUserTime = Number.MAX_SAFE_INTEGER;
      users.forEach((user: any) => {
        console.log("test = "+user.data.dateAdded);
        if(user.data.dateAdded < oldestTime)
        {
          oldestTime = user.data.dateAdded;
          this.currentReader = user.id;
        }
        if(user.id === this.activeUser)
        {
          currentUserTime = user.data.dateAdded;
        }
      });
      if(currentUserTime != Number.MAX_SAFE_INTEGER && currentUserTime <= oldestTime)
      {
        //Current user is oldest so allow them to read the next one.
        this.timeToRead = true;
        this.vibrateScreen();
        this.flashScreen();
        this.playAudio();
      }
      else
      {
        this.timeToRead = false;
        this.flashing = false;
      }
    });
  }

  public getAudioFile() : string
  {
    var randomNumber = Math.floor(Math.random() * 100);

    let baseAudio = [
      ["bark.wav",3],
      ["fart.mp3",3],
      ["knocking.wav",3],
      ["sam.mp3",3],
      ["whistle.wav",3],
      ["ping.wav",3],
    ];
    let probabilityAudio = [];
    baseAudio.forEach(audioElement => {
      var file = audioElement[0];
      var probability = audioElement[1];
      for (let index = 0; index < probability; index++) {
        probabilityAudio.push(file);        
      }
    });
    while(probabilityAudio.length < 100)
    {
     probabilityAudio.push("ping.wav"); 
    }
    return probabilityAudio[randomNumber];
  }

  public playAudio(){
    let audio = new Audio();
    audio.src = "../../../assets/audio/"+this.getAudioFile();
    audio.load();
    audio.play();
  }

  public flashScreen()
  {
    this.flashing = true;
  }

  public vibrateScreen()
  {
    if(navigator.vibrate)
    {
      navigator.vibrate(200); // vibrate for 200ms
    }
    else
    {
      
    }
  }

  public isUserAdmin() : boolean
  {
    var isAdmin : boolean = false;
    this.users.forEach((user : any) => {
      if(user.data.name === this.activeUser)
      {
        if(user.data.role === "admin")
        {
          isAdmin = true;
        }
      }
    });
    
    return isAdmin;
  }

  fetchAcknowledgement()
  {
    this.flashing = false;
    this.hideButton = true;
    this.databaseHelper.getRandomAcknowledgement().then(val => {
      console.log("ACKNOWLEDGEMENT = "+ JSON.stringify(val));
      if(val.success)
      {
        this.recipient = val.data.recipient;
        this.title = val.data.title;
        this.reason = val.data.reason;
        this.acknowledgementId = val.docId;
      }
      else
      {
        this.noAcknowledgement = true;
      }
    });
  }

  removeUserButtonPressed()
  {
    this.removeUserCount = this.removeUserCount + 1;
    
    if(this.removeUserCount == 3)
    {
      this.databaseHelper.removeUserFromCurrentReadingSession(this.currentReader).then(val =>{
        this.removeUserCount = 0;
      });
    }
      setTimeout(() => 
      {
          if(this.removeUserCount !== 3)
          {
            this.removeUserCount = 0;
          }
      },
      2000);
  }

  loadUsers()
  {
    this.databaseHelper.fetchUsers().then(val => {
      this.users = new Array();
      val.docs.forEach(doc => {
        var documentData : any = {data : doc.data(), docId : doc.id};
        if(documentData.data.name !== " Everyone" && documentData.data.name !== " Christmas Joke" && documentData.status !== "inactive")
        {
          this.users.push(documentData);
        }
      });
      this.users.sort((a :any ,b : any) => a.data.name < b.data.name ? -1 : a.data.name > b.data.name ? 1 : 0);
      this.users.forEach((user : any) => {
        console.log(user);
      });
    });
  }

  public completeButtonPressed()
  {
    this.timeToRead = false;
    this.hideButton = true;
    this.databaseHelper.completeAcknowledgements(this.acknowledgementId).then(val =>{
      console.log(val);
      this.joinSession();
      
    });
    this.noAcknowledgement = false;
    this.title = "";
    this.reason = "";
    this.recipient = "";
  }

  private addSourceToVideo(element : any, type : any, dataURI : any) {
    var source = document.createElement('source');
    source.src = dataURI;
    source.type = 'video/' + type;
    element.appendChild(source);
  }

  private base64(mimeType: any, base64: any) {
    return 'data:' + mimeType + ';base64,' + base64;
  };

}
