import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';

@Component({
  selector: 'app-secret-code',
  templateUrl: './secret-code.component.html',
  styleUrls: ['./secret-code.component.css']
})
export class SecretCodeComponent implements OnInit {

  public secretCode : string = '';
  public loading : boolean = false;

  constructor(private router: Router, private databaseHelper: DatabaseHelper) { }

  ngOnInit(): void {
  }

  codeSubmitButtonPressed()
  {
    this.databaseHelper.systemInfo().then(val =>{
      let data : any = val.data();
      if(this.secretCode === data.password)
      {
        this.databaseHelper.setActive();
        this.router.navigateByUrl('read-acknowledgement');
      }
    });
  }

  readShoutButtonPressed()
  {
    this.databaseHelper.systemInfo().then(val =>{
      let data : any = val.data();
      // console.log(val.data());
      console.log("Comparing: 1 "+ (data.systemActive + (60*60*24*1000)));
      console.log("Comparing: 2 "+Date.now());
      if(Date.now()  < (data.systemActive + (60*60*1*1000)))
      {
        console.log("IS ACTIVE");
        this.router.navigateByUrl('read-acknowledgement')
      }
      else
      {
        console.log("NOT ACTIVE");
        this.router.navigateByUrl('not-active')
      }
    });
  }

}
