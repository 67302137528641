<div class="page-wrapper">
<div class="content-wrapper">
<h1>Caution: Secret code required</h1>
<textarea class="form-control" placeholder="Code..." [(ngModel)]="secretCode"></textarea>
<div (click)="codeSubmitButtonPressed(); loading = true;" class="submit-button-container">
    <div *ngIf="loading" class="loader big-loader"></div>
    <button *ngIf="!loading" class="button-submit">Submit</button>
</div>
</div>
</div>
<div class="nav-bar-bottom">
    <div class="nav-link">
        <a routerLink="/form"><img src="assets/bullhorn.svg"></a>
    </div>
    <div class="nav-link">
        <a (click)="readShoutButtonPressed()"><img src="assets/heart.svg"></a>
    </div>
    <div class="nav-link">
        <a routerLink="/history" class="tab-selected"><img src="assets/history.svg"></a>
    </div>
</div>
