import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { retry } from 'rxjs/operators';
import { Alert, Logs } from 'selenium-webdriver';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  constructor(private router: Router, private databaseHelper: DatabaseHelper) { }

  public users: any;
  public allUsers: any;
  
  public what : string = "";
  public why : string = "";
  public search : string = "";
  
  public selectedUser: any = [];

  public selectedAdjective : string = "";
  public formattedName : string = "";
  public loading : boolean = false;

  ngOnInit(): void {
    this.loadAdjective();
    this.loadUsers();
  }

  loadAdjective()
  {
    let adjectivesArray = ["adept","brave","capable","considerate","courageous","faithful","fearless",      "frank",
      "humorous","knowledgeable",
      "loving",      "marvelous",
      "nice",      "optimistic",
      "passionate","persistent",
      "plucky",
      "proficient",
      "romantic",
      "self-confident",
      "sensible",
      "thoughtful",
      "warmhearted",
      "willing",
      "zestful"
    ];
    const randomAdjective = adjectivesArray[Math.floor(Math.random() * adjectivesArray.length)];
    this.selectedAdjective = randomAdjective;
  }

  loadUsers()
  {
    this.databaseHelper.fetchUsers().then(val => {
      this.users = new Array();
      this.allUsers = new Array();
      val.docs.forEach(doc => {
        var documentData = {data : doc.data() as any, docId : doc.id};
        if(documentData.data.status !== "inactive")
        {
          this.users.push(documentData);
        }
      });
      this.users.sort((a :any ,b : any) => a.data.name < b.data.name ? -1 : a.data.name > b.data.name ? 1 : 0);
      this.users.forEach((user : any) => {
        this.allUsers.push(user);
        console.log(user);
      });
    });
  }

  searchEmployees()
  {
    this.users = new Array();
    this.allUsers.forEach((user : any) => {
      console.log("Search = "+this.search);
      if(JSON.stringify(user.data.name).toLowerCase().includes(this.search.toLowerCase()))
      {
        console.log("Got user = "+JSON.stringify(user));
        this.users.push(user);
      }
    });
  }

  public getHumanString() : string
  {
    if(this.selectedUser.length >= 2)
    {
      return this.checkForFinley() ? "acquaintances" : "humans";
    }
    else
    {
      return this.checkForFinley() ? "fluffermuffin" : "human";
    }
  }

  public checkForFinley() : boolean
  {
    var foundFinley : boolean = false;
    if(this.selectedUser)
    {
      this.selectedUser.forEach((user : any, index: any) => {
        if(user.data.name == 'Finley')
        {
          foundFinley = true;
        }
      });
    }
    return foundFinley;
  }

  public noUsers()
  {
    if(this.selectedUser.length == 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  public userSelected(user: any)
  {
    if(this.selectedUser)
    {
      if(this.selectedUser.indexOf(user) > -1)
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    else
    {
      return false;
    }
  }

  private removeElementFromArray(element : any) {
    this.selectedUser.forEach((user : any, index: any) => {
      if(user == element)
      {
        this.selectedUser.splice(index,1);
      }
    });
  }

  public userClicked(user: any)
  {
    if(this.userSelected(user))
    {
      this.removeElementFromArray(user);
    }
    else
    {
      var jokeUser = false;
      this.selectedUser.forEach((loopUser : any) => {
        if(loopUser.data.name === " Christmas Joke")
        {
          jokeUser = true;
        }
      });
      if(jokeUser == false)
      {
        if(user.data.name == " Christmas Joke" && this.selectedUser.length >= 1)
        {

        }
        else
        {
          this.selectedUser.push(user);
          console.log("ARRAY = "+JSON.stringify(this.selectedUser));
          this.stepOneButtonPressed();
        }
      }
    }
    this.selectedUsersName();
  }

public navigateToStepOne()
{
  setTimeout(()=>{
    var screen = document.getElementById("screen-1");
    if(screen)
    {
      screen.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else
    {
      console.log("SCREEN IS NULL!");
    }
  }, 100)
}

  public stepOneButtonPressed()
  {
    setTimeout(()=>{
      var screen = document.getElementById("screen-2");
      if(screen)
      {
        screen.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      else
      {
        console.log("SCREEN IS NULL!");
      }
    }, 100)
  }

  public stepTwoButtonPressed()
  {
    setTimeout(()=>{
      var screen = document.getElementById("screen-3");
      if(screen)
      {
        screen.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      else
      {
        console.log("SCREEN IS NULL!");
      }
    }, 100)
  }

  public submitAcknowledgement()
  {
    this.databaseHelper.submitAcknowledgements(this.what,this.why,this.formattedName,this.selectedUser).then(val => {
      this.router.navigateByUrl('thanks')
    });
  }

  public selectedUsersName()
  {
    var usersNamesString : any = "";
    var userNameAka : any = "";
    if(this.selectedUser)
    {
      this.selectedUser.forEach((currentUser: any) => {
        if(usersNamesString == "")
        {
          usersNamesString = currentUser.data.name;
        }
        else
        {
          usersNamesString = usersNamesString + ", " + currentUser.data.name;
        }
        if(currentUser.data.name.length > 2)
        {
          userNameAka = userNameAka + currentUser.data.name.substring(0,3)
        }
        else
        {
          userNameAka = userNameAka + currentUser.data.name;
        }
      });
      if(this.selectedUser[0] === " Christmas Joke")
      {
        usersNamesString = " Christmas Joke"
      }
      if(this.selectedUser.length > 1)
      {
      var pos = usersNamesString.lastIndexOf(',');
      console.log("Names = "+usersNamesString);
      usersNamesString = usersNamesString.substring(0,pos) + " and" + usersNamesString.substring(pos+1)
      usersNamesString = usersNamesString + " A.K.A "+userNameAka;
      console.log("about to submit user = "+usersNamesString);
      }
      else
      {
        usersNamesString = usersNamesString + " A.K.A " + this.generateAKAName();
      }
    }
    this.formattedName = usersNamesString;
  }

  generateAKAName() : string
  {
    var adjList = [
      "angelic",
      "anticipating",
      "arctic",
      "blessed",
      "blustery",
      "born",
      "candlelit",
      "chilly",
      "Christian",
      "clear",
      "cold",
      "cozy",
      "crackling",
      "crisp",
      "crunchy",
      "crystalline",
      "dashing",
      "decorated",
      "enchanted",
      "excited",
      "exhausted",
      "faithful",
      "festive",
      "fireside",
      "flannel",
      "fleecy",
      "fluffy",
      "frosty",
      "frozen",
      "generous",
      "gift-wrapped",
      "giving",
      "glacial",
      "glistening",
      "golden",
      "gracious",
      "green",
      "gusty",
      "happy",
      "harsh",
      "hazy",
      "heated",
      "holiday",
      "holy",
      "ice-kissed",
      "icy",
      "indoor",
      "jingling",
      "jolly",
      "joyful",
      "joyous",
      "knee-deep",
      "lavish",
      "lighted",
      "loving",
      "magical",
      "melting",
      "merry",
      "miraculous",
      "misty",
      "nippy",
      "northern",
      "opaline",
      "piney",
      "polar",
      "powdery",
      "pure white",
      "red",
      "red & green",
      "religious",
      "sacred",
      "seasonal",
      "sedentary",
      "slippery",
      "slushy",
      "snowbound",
      "snowed in",
      "snowing",
      "snowy",
      "snug",
      "sparkling",
      "sparkly",
      "special",
      "spiced",
      "spiked",
      "spirited",
      "spiritual",
      "symbolic",
      "toasted",
      "toasting",
      "toasty",
      "triumphant",
      "very merry",
      "warm",
      "white",
      "wintertime",
      "wintery",
      "wonderful",
      "wondrous",
      "woolen",
      "yuletide",
      "zippy",
    ]

    var nounArray = [
      "Rudolph",
      "mass",
      "joy",
      "plums",
      "present",
      "snow",
      "nativity scene",
      "Jerusalem",
      "sleigh bells",
      "winter",
      "birth of Christ",
      "stockings",
      "Magi",
      "xmas",
      "tree",
      "season’s greetings",
      "baby",
      "dressing",
      "Christmas cookie",
      "snowflake",
      "sugar plums",
      "caroler",
      "Bethlehem",
      "pine",
      "Christ Child",
      "Christmas gift",
      "holiday tradition",
      "tradition",
      "Scrooge",
      "blessing",
      "nativity",
      "holiday greetings",
      "presents",
      "cedar",
      "Christmas wish",
      "shopping",
      "gift boxes",
      "Christmas lights",
      "wonderment",
      "snow angel",
      "Christmas miracle",
      "trimmings",
      "babe",
      "secret Santa",
      "festival",
      "busy elves",
      "wise men",
      "gift list",
      "eggnog",
      "announcement",
      "Christmas morning",
      "holiday spirit",
      "figgy pudding",
      "Father Christmas",
      "tinsel",
      "jingle bells",
      "gifts",
      "greetings",
      "Jesus Christ",
      "candles",
      "sled",
      "birth",
      "incarnation",
      "Mrs. Claus",
      "turkey",
      "snowfall",
      "sales",
      "coal",
      "bough",
      "glad tidings",
      "Frosty",
      "spirit",
      "foil",
      "dolls",
      "noel",
      "gift",
      "Christmas card",
      "Grinch",
      "Christmas",
      "list",
      "Christmas present",
      "peace",
      "Mary",
      "Christ",
      "workshop",
      "celebration",
      "Kriss Kringle",
      "holiday celebration",
      "Christmas day",
      "holy day",
      "cranberry sauce",
      "Christmas star",
      "parade",
      "icicles",
      "mirth",
      "gathering",
      "ham",
      "love",
      "Christmas carol",
      "holiday gala",
      "holiday tidings",
      "reindeer",
      "silver bells",
      "holiday mirth",
      "Jesus",
      "holy celebration",
      "reverance",
      "angel",
      "holiday favorite",
      "Christmas past",
      "poinsettia",
      "miracle",
      "Menorah",
      "holly",
      "Ebenezer",
      "merriment",
      "Savior",
      "Advent",
      "sleigh",
      "gloria",
      "elves",
      "bells",
      "holiday",
      "yule log",
      "ribbon",
      "holiday merriment",
      "ornament",
      "tiding",
      "holiday magic",
      "Christmas future",
      "jolly wishes",
      "advent calendar",
      "December",
      "frankincense",
      "prayer",
      "Tannenbaum",
      "holiday cheer",
      "family",
      "family reunion",
      "star",
      "snowman",
      "gala",
      "rooftop",
      "Christmas Eve",
      "inn",
      "lists",
      "Yule",
      "Christmas play",
      "elf",
      "chestnuts",
      "feast",
      "fresh snowfall",
      "Chistmas sales",
      "starlight",
      "Christmas tree",
      "mince pie",
      "manger",
      "prophecy",
      "cheer",
      "holiday party",
      "mistletoe",
      "gingerbread house",
      "season",
      "decorations",
      "fruitcake",
      "stocking",
      "toys",
      "December 25th",
      "trumpets",
      "wreath",
      "peppermint",
      "shepherd",
      "buffet dinner",
      "St. Nick",
      "gift exchange",
      "service",
      "goodwill",
      "winter wonderland",
      "family gathering",
      "God’s holy son",
      "wrapping paper",
      "camel",
      "tree stand",
      "chimney",
      "carol",
      "myrrh",
      "nutcracker",
      "candy cane",
      "Santa",
      "North Pole",
      "sugarplums",
      "Joseph",
      "holiday classic",
      "home",
      "Christmas pageant",
      "fir",
      "holly berry",
    ]
    const randomAdjective = adjList[Math.floor(Math.random() * adjList.length)];
    const randomNoun = nounArray[Math.floor(Math.random() * nounArray.length)];

    return randomAdjective + "-"+randomNoun;
  }

  // generateAKAName() : string
  // {
  //   var adjList = [
  //   "adorable",
  //   "agreeable",
  //   "amused",
  //   "annoying",
  //   "ashamed",
  //   "awful",
  //   "better",
  //   "bloody",
  //   "blushing",
  //   "brave",
  //   "busy",
  //   "cautious",
  //   "clean",
  //   "cloudy",
  //   "combative",
  //   "condemned",
  //   "courageous",
  //   "crowded",
  //   "cute",
  //   "dead",
  //   "delightful",
  //   "different",
  //   "distinct",
  //   "doubtful",
  //   "adventurous",
  //   "alert",
  //   "angry",
  //   "anxious",
  //   "attractive",
  //   "bad",
  //   "bewildered",
  //   "blue",
  //   "bored",
  //   "breakable",
  //   "calm",
  //   "charming",
  //   "clear",
  //   "clumsy",
  //   "comfortable",
  //   "confused",
  //   "crazy",
  //   "cruel",
  //   "dangerous",
  //   "defeated",
  //   "depressed",
  //   "difficult",
  //   "disturbed",
  //   "drab",
  //   "aggressive",
  //   "alive",
  //   "annoyed",
  //   "arrogant",
  //   "average",
  //   "beautiful",
  //   "black",
  //   "blue-eyed",
  //   "brainy",
  //   "bright",
  //   "careful",
  //   "cheerful",
  //   "clever",
  //   "colorful",
  //   "concerned",
  //   "cooperative",
  //   "creepy",
  //   "curious",
  //   "dark",
  //   "defiant",
  //   "determined",
  //   "disgusted",
  //   "dizzy",
  //   "dull",
  //   "eager",
  //   "elegant",
  //   "encouraging",
  //   "envious",
  //   "expensive",
  //   "faithful",
  //   "fantastic",
  //   "fine",
  //   "frail",
  //   "frightened",
  //   "gifted",
  //   "glorious",
  //   "graceful",
  //   "grumpy",
  //   "healthy",
  //   "hilarious",
  //   "horrible",
  //   "ill",
  //   "inexpensive",
  //   "itchy",
  //   "jolly",
  //   "elated",
  //   "enchanting",
  //   "enthusiastic",
  //   "excited",
  //   "fair",
  //   "fancy",
  //   "filthy",
  //   "fragile",
  //   "friendly",
  //   "gentle",
  //   "gleaming",
  //   "gorgeous",
  //   "grotesque",
  //   "happy",
  //   "helpless",
  //   "homely",
  //   "hurt",
  //   "impossible",
  //   "inquisitive",
  //   "jittery",
  //   "kind",
  //   "easy",
  //   "embarrassed",
  //   "energetic",
  //   "evil",
  //   "exuberant",
  //   "famous",
  //   "fierce",
  //   "foolish",
  //   "frantic",
  //   "funny",
  //   "glamorous",
  //   "good",
  //   "grieving",
  //   "handsome",
  //   "helpful",
  //   "homeless",
  //   "hungry",
  //   "important",
  //   "innocent",
  //   "jealous",
  //   "joyous",
  //   "lazy",
  //   "lonely",
  //   "lucky",
  //   "modern",
  //   "mushy",
  //   "naughty",
  //   "nutty",
  //   "odd",
  //   "outrageous",
  //   "perfect",
  //   "poised",
  //   "precious",
  //   "putrid",
  //   "real",
  //   "rich",
  //   "shiny",
  //   "lively",
  //   "lovely",
  //   "misty",
  //   "muddy",
  //   "nasty",
  //   "nice",
  //   "obnoxious",
  //   "open",
  //   "panicky",
  //   "pleasant",
  //   "powerful",
  //   "proud",
  //   "quaint",
  //   "repulsive",
  //   "selfish",
  //   "silly",
  //   "light",
  //   "long",
  //   "magnificent",
  //   "motionless",
  //   "mysterious",
  //   "nervous",
  //   "obedient",
  //   "old-fashioned",
  //   "outstanding",
  //   "plain",
  //   "poor",
  //   "prickly",
  //   "puzzled",
  //   "relieved",
  //   "scary",
  //   "shy",
  //   "sleepy",
  //   "sore",
  //   "spotless",
  //   "stupid",
  //   "smoggy",
  //   "splendid",
  //   "strange",
  //   "super",
  //   "smiling",
  //   "sparkling",
  //   "stormy",
  //   "successful",
  //   "talented",
  //   "tender",
  //   "thankful",
  //   "tired",
  //   "ugliest",
  //   "unsightly",
  //   "uptight",
  //   "vivacious",
  //   "wicked",
  //   "witty",
  //   "wrong",
  //   "tasty",
  //   "terrible",
  //   "thoughtless",
  //   "troubled",
  //   "uninterested",
  //   "upset",
  //   "victorious",
  //   "weary",
  //   "wild",
  //   "worrisome",
  //   "zealous",
  //   "tame",
  //   "tense",
  //   "thoughtful",
  //   "tough",
  //   "ugly",
  //   "unusual",
  //   "vast",
  //   "wandering",
  //   "wide-eyed",
  //   "worried",
  //   "zany"
  //   ]

  //   var nounArray = [
  //     "Actor",
  //     "Advertisement",
  //     "Afternoon",
  //     "Airport",
  //     "Ambulance",
  //     "Animal",
  //     "Answer",
  //     "Apple",
  //     "Army",
  //     "Australia",
  //     "Balloon",
  //     "Banana",
  //     "Battery",
  //     "Beach",
  //     "Beard",
  //     "Bed",
  //     "Belgium",
  //     "Boy",
  //     "Branch",
  //     "Breakfast",
  //     "Brother",
  //     "Camera",
  //     "Candle",
  //     "Car",
  //     "Caravan",
  //     "Carpet",
  //     "Cartoon",
  //     "China",
  //     "Church",
  //     "Crayon",
  //     "Crowd",
  //     "Daughter",
  //     "Death",
  //     "Denmark",
  //     "Diamond",
  //     "Dinner",
  //     "Disease",
  //     "Doctor",
  //     "Dog",
  //     "Dream",
  //     "Dress",
  //     "Easter",
  //     "Egg",
  //     "Eggplant",
  //     "Egypt",
  //     "Elephant",
  //     "Energy",
  //     "Engine",
  //     "England",
  //     "Evening",
  //     "Eye",
  //     "Family",
  //     "Finland",
  //     "Fish",
  //     "Flag",
  //     "Flower",
  //     "Football",
  //     "Forest",
  //     "Fountain",
  //     "France",
  //     "Furniture",
  //     "Painting",
  //     "Parrot",
  //     "Pencil",
  //     "Piano",
  //     "Pillow",
  //     "Pizza",
  //     "Planet",
  //     "Plastic",
  //     "Portugal",
  //     "Potato",
  //     "Queen",
  //     "Quill",
  //     "Rain",
  //     "Rainbow",
  //     "Raincoat",
  //     "Refrigerator",
  //     "Restaurant",
  //     "River",
  //     "Rocket",
  //     "Room",
  //     "Rose",
  //     "Russia",
  //     "Sandwich",
  //     "School",
  //     "Scooter",
  //     "Shampoo",
  //     "Shoe",
  //     "Soccer",
  //     "Spoon",
  //     "Stone",
  //     "Sugar",
  //     "Sweden",
  //     "Teacher",
  //     "Telephone",
  //     "Television",
  //     "Tent",
  //     "Thailand",
  //     "Tomato",
  //     "Toothbrush",
  //     "Traffic",
  //     "Train",
  //     "Truck",
  //     "Uganda",
  //     "Umbrella",
  //     "Van",
  //     "Vase",
  //     "Vegetable",
  //     "Vulture",
  //     "Wall",
  //     "Whale",
  //     "Window",
  //     "Wire",
  //     "Xylophone",
  //     "Yacht",
  //     "Yak",
  //     "Zebra",
  //     "Zoo",
  //     "Garden",
  //     "Gas",
  //     "Girl",
  //     "Glass",
  //     "Gold",
  //     "Grass",
  //     "Greece",
  //     "Guitar",
  //     "Hair",
  //     "Hamburger",
  //     "Helicopter",
  //     "Helmet",
  //     "Holiday",
  //     "Honey",
  //     "Horse",
  //     "Hospital",
  //     "House",
  //     "Hydrogen",
  //     "Ice",
  //     "Insect",
  //     "Insurance",
  //     "Iron",
  //     "Island",
  //     "Jackal",
  //     "Jelly",
  //     "Jewellery",
  //     "Jordan",
  //     "Juice",
  //     "Kangaroo",
  //     "King",
  //     "Kitchen",
  //     "Kite",
  //     "Knife",
  //     "Lamp",
  //     "Lawyer",
  //     "Leather",
  //     "Library",
  //     "Lighter",
  //     "Lion",
  //     "Lizard",
  //     "Lock",
  //     "London",
  //     "Lunch",
  //     "Machine",
  //     "Magazine",
  //     "Magician",
  //     "Manchester",
  //     "Market",
  //     "Match",
  //     "Microphone",
  //     "Monkey",
  //     "Morning",
  //     "Motorcycle",
  //     "Nail",
  //     "Napkin",
  //     "Needle",
  //     "Nest",
  //     "Nigeria",
  //     "Night",
  //     "Notebook",
  //     "Ocean",
  //     "Oil",
  //     "Orange",
  //     "Oxygen",
  //     "Oyster"
  //   ]
  //   const randomAdjective = adjList[Math.floor(Math.random() * adjList.length)];
  //   const randomNoun = nounArray[Math.floor(Math.random() * nounArray.length)];

  //   return randomAdjective + "-"+randomNoun;
  // }

  readShoutButtonPressed()
  {
    this.databaseHelper.systemInfo().then(val =>{
      let data : any = val.data();
      // console.log(val.data());
      console.log("Comparing: 1 "+ (data.systemActive + (60*60*24*1000)));
      console.log("Comparing: 2 "+Date.now());
      if(Date.now()  < (data.systemActive + (60*60*1*1000)))
      {
        console.log("IS ACTIVE");
        this.router.navigateByUrl('read-acknowledgement')
      }
      else
      {
        console.log("NOT ACTIVE");
        this.router.navigateByUrl('not-active')
      }
    });
  }

  uploadExistingAcknowledgements()
  {
    var stringsArray = new Array();
    
  stringsArray.push('Bri,Office-move organiser,Doing a smashing job getting us to kwp without a hiccup ');

  stringsArray.forEach((award : String) => {
    award = award.replace(", ","+++").replace(", ","+++").replace(", ","+++").replace(", ","+++").replace(", ","+++");
    var name = award.split(",")[0].replace("+++",", ").replace("+++",", ").replace("+++",", ");
    var title = award.split(",")[1].replace("+++",", ").replace("+++",", ").replace("+++",", ");
    var description = award.split(",")[2].replace("+++",", ").replace("+++",", ").replace("+++",", ");
    console.log("name = "+name+" | title = "+title+" | award = "+description);
    // this.databaseHelper.submitAcknowledgements(title,description,name).then(val => {

    // });
    });

  }

}
