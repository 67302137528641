import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})

export class HistoryComponent implements OnInit {

  constructor(private router: Router, private databaseHelper: DatabaseHelper) { }

  public previousSessions = new Array();
  public allAcknowledgeents = new Array();
  public selectedSession = 0;
  public selectedSessions = new Array();

  ngOnInit(): void {
    this.loadHistorySessions();
  }

  loadHistorySessions()
  {
    this.databaseHelper.fetchPreviousSessions().then(val => {
      val.docs.forEach(doc => {
        var documentData : any = {data : doc.data(), docId : doc.id, acknowledgements : new Array()};
          this.previousSessions.push(documentData);
      });
      this.previousSessions.sort((a :any ,b : any) => a.data.timestamp < b.data.timestamp ? 1 : a.data.timestamp > b.data.timestamp ? -1 : 0);
    });
  }

  loadAcknowledgements(session : number)
  {
    if(!this.selectedSessions.includes(session))
    {
    this.selectedSessions.push(session);
    console.log("Loading = "+session);
    this.selectedSession = session;
    console.log("Loading #2 = "+this.selectedSession);
    this.databaseHelper.getSessionCompletedAcknowledgements({session: this.selectedSession+""}).then(val =>{
      // this.databaseHelper.getAllCompletedAcknowledgements().then(val =>{
        console.log(JSON.stringify(val));
        this.allAcknowledgeents = new Array();
        val.forEach((doc : any) => {
          var documentData : any = {data : doc.data, docId : doc.id};
            this.allAcknowledgeents.push(documentData);
        });
        this.allAcknowledgeents.forEach(acknowledgement => {
          var sessionFound : boolean = false;
              this.previousSessions.forEach(session => {
                console.log("Checking ack = "+session.data.timestamp + " vs "+acknowledgement.data.completionTime);
                if(session.data.timestamp < acknowledgement.data.completionTime && sessionFound == false)
                {
                  session.acknowledgements.push(acknowledgement);
                  console.log("PUSHING! = "+session.acknowledgements.length);
                  sessionFound = true;
                  // return;
                }
              });
        });
      });
            
    }
  }

  readShoutButtonPressed()
  {
    this.databaseHelper.systemInfo().then(val =>{
      let data : any = val.data();
      // console.log(val.data());
      console.log("Comparing: 1 "+ (data.systemActive + (60*60*24*1000)));
      console.log("Comparing: 2 "+Date.now());
      if(Date.now()  < (data.systemActive + (60*60*1*1000)))
      {
        console.log("IS ACTIVE");
        this.router.navigateByUrl('read-acknowledgement')
      }
      else
      {
        console.log("NOT ACTIVE");
        this.router.navigateByUrl('not-active')
      }
    });
  }

}
