import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReadAcknowledgementComponent } from './pages/read-acknowledgement/read-acknowledgement.component';
import { FormComponent } from './pages/form/form.component';
import { HomeComponent } from './pages/home/home.component';
import { ThanksComponent } from './pages/thanks/thanks.component';
import { NotActiveComponent } from './pages/not-active/not-active.component';
import { SecretCodeComponent } from './pages/secret-code/secret-code.component';
import { HistoryComponent } from './pages/history/history.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'form',
    component: FormComponent,
  },
  {
    path: 'thanks',
    component: ThanksComponent,
  },
  {
    path: 'read-acknowledgement',
    component: ReadAcknowledgementComponent,
  },
  {
    path: 'not-active',
    component: NotActiveComponent,
  },
  {
    path: 'secret-code',
    component: SecretCodeComponent,
  },
  {
    path: 'history',
    component: HistoryComponent,
  },
  {
    path: '**',
    component: HomeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
