import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';

declare var fade: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private databaseHelper: DatabaseHelper) { }

  ngOnInit(): void {
    // new fade();
    this.databaseHelper.setupUsers();
    // this.databaseHelper.updateAllCompletedAcknowledgements();
    setTimeout(()=>{
      this.buttonClicked();
    }, 1000)
  }

  buttonClicked() {
    this.router.navigateByUrl('form')
  };

}
