// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyByDRhG-RUdC0nZl9iJqZQixzNG7I8eBRw",
    authDomain: "digital-noir-acknowledgements.firebaseapp.com",
    projectId: "digital-noir-acknowledgements",
    storageBucket: "digital-noir-acknowledgements.appspot.com",
    messagingSenderId: "648377332692",
    appId: "1:648377332692:web:46c653e9b5786c4f005c41"
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
