<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Home</title>
</head>

<body>
    <div class="app-wrapper">
        <main>
            <div class="heading-container">
                <button (click)="buttonClicked()" class="cta-shout"><img class="intro-logo" src="assets/KWPX_Logo.svg" alt=""></button>
            </div>
        </main>
    </div>
</body>

</html>