<div class="main-content">
<div *ngFor="let previousSession of previousSessions">
    <p class="date" (click)="loadAcknowledgements(previousSession.data.timestamp)">{{(previousSession.data.timestamp | date: "d") | dateSuffix}} {{previousSession.data.timestamp | date: "MMM YYYY"}} ></p>
    <div class="acknowledgement" *ngFor="let acknowledgement of previousSession.acknowledgements">
        <h1 class="title">{{acknowledgement.data.title}}</h1>
        <h2 class="description">{{acknowledgement.data.recipient}}</h2>
        <h2 class="description">{{acknowledgement.data.reason}}</h2>
    </div>
</div>
<!-- <div class="acknowledgement" *ngFor="let acknowledgement of allAcknowledgeents">
    <h1 class="title">{{acknowledgement.data.title}}</h1>
    <h2 class="description">{{acknowledgement.data.recipient}}</h2>
    <h2 class="description">{{acknowledgement.data.reason}}</h2>
</div> -->
</div>

<div class="nav-bar-bottom">
    <div class="nav-link">
        <a routerLink="/form"><img src="assets/bullhorn.svg"></a>
    </div>
    <div class="nav-link">
        <a (click)="readShoutButtonPressed()"><img src="assets/heart.svg"></a>
    </div>
    <div class="nav-link">
        <a routerLink="/history"><img class="tab-selected" src="assets/history.svg"></a>
    </div>
</div>