import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage} from '@angular/fire/compat/storage';
@Injectable()
/**
 * This is where all the endpoint calls are made.
 * The general state of the application is stored here during runtime.,
 * 
 */
export class DatabaseHelper {

    constructor(private firestore: AngularFirestore, private functions: AngularFireFunctions, private storage: AngularFireStorage) {
            
    }

    public submitAcknowledgements(title: string, reason: string, recipient: string,users: any[]) {
        var userNames: any[] = [];
        users.forEach(user => {
            userNames.push(user.data.name.trim());
        });
        return this.functions.httpsCallable("submitAcknowledgements")({title: title,reason:reason,recipient:recipient,complete:false, dateCreated:  Date.now() + '' , users: userNames}).toPromise();
    }

    public completeAcknowledgements(docId : string) {
        return this.functions.httpsCallable("completeAcknowledgements")({documentId : docId}).toPromise();
    }

    public getRandomAcknowledgement() {
        return this.functions.httpsCallable("getRandomAcknowledgement")({}).toPromise();
    }

    
    public getSessionCompletedAcknowledgements(data : any) {
        return this.functions.httpsCallable("getSessionCompletedAcknowledgements")(data).toPromise();
    }

    public getAllCompletedAcknowledgements() {
        return this.functions.httpsCallable("getAllCompletedAcknowledgements")({}).toPromise();
    }

    public fetchPreviousSessions() {
        return this.firestore.collection(`sessionActivations`).get().toPromise();
    }

    public fetchUsers() {
        return this.firestore.collection(`users`).get().toPromise();
    }

    public fetchCurrentUser() {
        return this.firestore.collection(`activeUsers`).get().toPromise();
    }

    public async checkInWithUser(currentUser: string) : Promise<boolean>{
        console.log("Checkin "+currentUser);
        var userExists = false;''
        return new Promise((resolve, reject) => {
            this.fetchCurrentUser().then(val => {
                console.log("Checkin2"+val.docs);
                val.docs.forEach(doc => {
                var documentData = {data : doc.data(), docId : doc.id};
                    if(currentUser === documentData.docId)
                    {
                        console.log("User exists");
                        userExists = true;
                    }
                    else
                    {
                        console.log("User doesn't exist");
                    }
                });
                console.log("returning = "+userExists);
                resolve(userExists);
            });
        });
    }

    public joinCurrentReadingSession(currentUser: string) {
        return this.functions.httpsCallable("joinCurrentReadingSession")({currentUser: currentUser,updateDateTime : Date.now()}).toPromise();
    }

    public removeUserFromCurrentReadingSession(user: string) {
        return this.functions.httpsCallable("removeUserFromSession")({user: user}).toPromise();
    }

    

    public checkOutWithUser() {
        //Remove the user from the current active array
        return this.functions.httpsCallable("checkOutWithUser")({usersName : "David"}).toPromise();
    }

    public systemInfo() {
        return this.firestore.doc(`systemInfo/systemInfo`).get().toPromise();
    }

    public async setActive() {
        await this.functions.httpsCallable("clearReadingSession")({}).toPromise();
        this.firestore.doc(`sessionActivations/`+Date.now()).set({timestamp : Date.now()},{merge : true});
        return this.firestore.doc(`systemInfo/systemInfo`).set({systemActive : Date.now()},{merge : true});
    }

    // private readonly storage: Storage = inject(Storage);

    public async updateAllCompletedAcknowledgements() {
        await this.functions.httpsCallable("updateAllCompletedAcknowledgements")({}).toPromise();
    }

    public setupUsers() {
        this.users.forEach(async (user : any) => {
            console.log("setup user");
            if(user.imported == false)
            {
                try {
                    var downloadUrl = await this.storage.ref(user.imageName.replace(/ /g,"_")).getDownloadURL().toPromise();
                    console.log("Download url = "+downloadUrl);
                    this.firestore.collection(`users`).doc(user.name).set({name: user.name, imageUrl: downloadUrl, team: user.team},{merge: true});
                } catch (error) {
                    console.log("couldn't find = >"+user.imageName.replace(/ /g,"_")+"<");
                    downloadUrl = "";
                    this.firestore.collection(`users`).doc(user.name).set({name: user.name, imageUrl: downloadUrl, team: user.team},{merge: true});   
                }
            }
        });
        return 
    }

    users : any = [
        {name: "Alec Davis", team: "kwpx", imageName: "Alec Davis_Front.jpg", imported: true, status: "active"},
        {name: "Alex Cotterell", team: "kwpx", imageName: "Alex Cotterell_Front.jpg", imported: true, status: "active"},
        {name: "Alex Stewart ", team: "kwpx", imageName: "Alex Stewart_Front.jpg", imported: true, status: "active"},
        {name: "Alex Mahon", team: "kwpx", imageName: "Alex Mahon_Front.jpg", imported: true, status: "active"},
        {name: "Alice Jordan", team: "kwpx", imageName: "Alice Jordan_Front.jpg", imported: true, status: "active"},
        {name: "Amanda Lopes", team: "kwpx", imageName: "Amanda Lopes_Front.jpg", imported: true, status: "active"},
        {name: "Andrew Breeding", team: "kwpx", imageName: "Andy Breeding_Front.jpg", imported: true, status: "active"},
        {name: "Ben Turner", team: "kwpx", imageName: "Ben Turner_Front.jpg", imported: true, status: "active"},
        {name: "Benjamin Spry", team: "kwpx", imageName: "Ben Spry_Front.jpg", imported: true, status: "active"},
        {name: "Brett Jacobs", team: "kwpx", imageName: "Brett Jacobs_Front.jpg", imported: true, status: "active"},
        {name: "Briony Kyloh", team: "kwpx", imageName: "Briony Kyloh_Front.jpg", imported: true, status: "active"},
        {name: "Caitlin Massey", team: "kwpx", imageName: "Caitlin Massey_Front.jpg", imported: true, status: "active"},
        {name: "Caitlin Howells", team: "kwpx", imageName: "Caitlin Howells_Front.jpg", imported: true, status: "active"},
        {name: "Caleb Chadwick", team: "kwpx", imageName: "Caleb Chadwick_Front.jpg", imported: true, status: "active"},
        {name: "Callum Hull", team: "kwpx", imageName: "Callum Hull_Front.jpg", imported: true, status: "active"},
        {name: "Camila Cunha Rahde", team: "kwpx", imageName: "Camila Cunha Rahde_Front.jpg", imported: true, status: "active"},
        {name: "Carlie Chenoweth", team: "kwpx", imageName: "Carlie Chenoweth_Front.jpg", imported: true, status: "active"},
        {name: "Charli Day", team: "kwpx", imageName: "Charli Day_Front.jpg", imported: true, status: "active"},
        {name: "Chris Gear", team: "kwpx", imageName: "Chris Gear_Front.jpg", imported: true, status: "active"},
        {name: "Con Kirgianis", team: "kwpx", imageName: "Con Kirgianis_Front.jpg", imported: true, status: "active"},
        {name: "Corey Swaffer", team: "kwpx", imageName: "Corey Swaffer_Front.jpg", imported: true, status: "active"},
        {name: "Damon Maslen", team: "kwpx", imageName: "Damon Maslen_Front.jpg", imported: true, status: "active"},
        {name: "Danielle Ng", team: "kwpx", imageName: "Dani Ng_Front.jpg", imported: true, status: "active"},
        {name: "David Merenda", team: "kwpx", imageName: "David Merenda_Front.jpg", imported: true, status: "active"},
        {name: "David O'Loughlin", team: "kwpx", imageName: "David OLoughlin_Front.jpg", imported: true, status: "active"},
        {name: "David Zules", team: "kwpx", imageName: "David Zules_Front.jpg", imported: true, status: "active"},
        {name: "David Pyatt", team: "kwpx", imageName: "David Pyatt_Front.jpg", imported: true, status: "active"},
        {name: "Dru Mincher", team: "kwpx", imageName: "Dru Mincher_Front.jpg", imported: true, status: "active"},
        {name: "Elaine Zhang", team: "kwpx", imageName: "Elaine Zhang_Front.jpg", imported: true, status: "active"},
        {name: "Ella-Maude Wilson", team: "kwpx", imageName: "Ella Wilson_Front.jpg", imported: true, status: "active"},
        {name: "Emily Johnson", team: "kwpx", imageName: "Em Johnson_Front.jpg", imported: true, status: "active"},
        {name: "Emma Flaherty", team: "kwpx", imageName: "Emma Flaherty_Front.jpg", imported: true, status: "active"},
        {name: "Emma Hockney", team: "kwpx", imageName: "Emma Hockney_Front.jpg", imported: true, status: "active"},
        {name: "Emma-Kate Hinton", team: "kwpx", imageName: "EK Hinton_Front.jpg", imported: true, status: "active"},
        {name: "Enrico Becker", team: "kwpx", imageName: "Enrico Becker_Front.jpg", imported: true, status: "active"},
        {name: "Georgia Rowntree", team: "kwpx", imageName: "Georgia Rowntree_Front.jpg", imported: true, status: "active"},
        {name: "Georgia Prime", team: "kwpx", imageName: "Georgia Prime_Front.jpg", imported: true, status: "active"},
        {name: "Hannah Guilford", team: "kwpx", imageName: "Hannah Guilford_Front.jpg", imported: true, status: "active"},
        {name: "Haydon Macleod", team: "kwpx", imageName: "Haydon Macleod_Front.jpg", imported: true, status: "active"},
        {name: "Jaimee Shurdington", team: "kwpx", imageName: "Jaimee Shurdington_Front.jpg", imported: true, status: "active"},
        {name: "Jodie Palmer", team: "kwpx", imageName: "Jodie Palmer_Front.jpg", imported: true, status: "active"},
        {name: "Joel van der Knaap", team: "kwpx", imageName: "Joel_VanDerKnaap_Front.jpg", imported: true, status: "active"},
        {name: "John Baker", team: "kwpx", imageName: "John Baker_Front.jpg", imported: true, status: "active"},
        {name: "Josh Fanning", team: "kwpx", imageName: "Josh Fanning_Front.jpg", imported: true, status: "active"},
        {name: "Kate Moroney", team: "kwpx", imageName: "Kate Moroney_Front.jpg", imported: true, status: "active"},
        {name: "Kayla Bath", team: "kwpx", imageName: "Kayla Bath_Front.jpg", imported: true, status: "active"},
        {name: "Kellie Grant", team: "kwpx", imageName: "Kellie Grant_Front.jpg", imported: true, status: "active"},
        {name: "Kelly Tataryn", team: "kwpx", imageName: "Kelly Tataryn_Front.jpg", imported: true, status: "active"},
        {name: "Luc Thomas", team: "kwpx", imageName: "Luc Thomas_Front.jpg", imported: true, status: "active"},
        {name: "Lucy Byrnes", team: "kwpx", imageName: "Lucy Byrnes_Front.jpg", imported: true, status: "active"},
        {name: "Madeleine Papilion", team: "kwpx", imageName: "Maddy Papilion_Front.jpg", imported: true, status: "active"},
        {name: "Marine Monbeig", team: "kwpx", imageName: "Marine Monbeig_Front.jpg", imported: true, status: "active"},
        {name: "Melanie Hammond ", team: "kwpx", imageName: "Mel Hammond_Front.jpg", imported: true, status: "active"},
        {name: "Michael Frier", team: "kwpx", imageName: "Mike Frier_Front.jpg", imported: true, status: "active"},
        {name: "Michelle Grogan", team: "kwpx", imageName: "Mish Grogan_Front.jpg", imported: true, status: "active"},
        {name: "Miguel Gonzalez", team: "kwpx", imageName: "Miguel_Gonzalez_Front.jpg", imported: true, status: "active"},
        {name: "Monica Lawrie", team: "kwpx", imageName: "Mon Lawrie_Front.jpg", imported: true, status: "active"},
        {name: "Natalie Morley", team: "kwpx", imageName: "Nat Morley_Front.jpg", imported: true, status: "active"},
        {name: "Neil Phillips", team: "kwpx", imageName: "Neil Phillips_Front.jpg", imported: true, status: "active"},
        {name: "Nick Bozic", team: "kwpx", imageName: "Nick Bozic_Front.jpg", imported: true, status: "active"},
        {name: "Olivia Brito", team: "kwpx", imageName: "Olivia Brito_Front.jpg", imported: true, status: "active"},
        {name: "Pasan Wijesuriya", team: "kwpx", imageName: "Pasan Wijesuriya_Front.jpg", imported: true, status: "active"},
        {name: "Pat Fox", team: "kwpx", imageName: "Pat Fox_Front.jpg", imported: true, status: "active"},
        {name: "Paul Murray", team: "kwpx", imageName: "Paul Murray_Front.jpg", imported: true, status: "active"},
        {name: "Petrea Nancarrow", team: "kwpx", imageName: "Petrea Nancarrow_Front.jpg", imported: true, status: "active"},
        {name: "Prasetyo Priambodo", team: "kwpx", imageName: "Pras Priambodo_Front.jpg", imported: true, status: "active"},
        {name: "Renee Quinn", team: "kwpx", imageName: "Renee Quinn_Front.jpg", imported: true, status: "active"},
        {name: "Ronald Alaby", team: "kwpx", imageName: "Ronald Alaby_Front.jpg", imported: true, status: "active"},
        {name: "Ryan Shipway", team: "kwpx", imageName: "Shippy_Front.jpg", imported: true, status: "active"},
        {name: "Sahara Boniface", team: "kwpx", imageName: "Sahara_Boniface_Front.jpg", imported: true, status: "active"},
        {name: "Sam Coppola", team: "kwpx", imageName: "Sam Coppola_Front.jpg", imported: true, status: "active"},
        {name: "Sam Davies ", team: "kwpx", imageName: "Sam Davies_Front.jpg", imported: true, status: "active"},
        {name: "Sam Talbot", team: "kwpx", imageName: "Sam Talbot_Front.jpg", imported: true, status: "active"},
        {name: "Sarah Di Iorio", team: "kwpx", imageName: "Sarah_DiIorio_Front.jpg", imported: true, status: "active"},
        {name: "Sarah Aboulhosn", team: "kwpx", imageName: "Sarah Aboulhosn_Front.jpg", imported: true, status: "active"},
        {name: "Shyam Pindoria", team: "kwpx", imageName: "Shyam Pindoria_Front.jpg", imported: true, status: "active"},
        {name: "Sophie Ross", team: "kwpx", imageName: "Sophie Ross_Front.jpg", imported: true, status: "active"},
        {name: "Stephanie Diaz", team: "kwpx", imageName: "Stephanie Diaz_Front.jpg", imported: true, status: "active"},
        {name: "Tanya Salvi", team: "kwpx", imageName: "Tanya Salvi_Front.jpg", imported: true, status: "active"},
        {name: "Taras Zagajewski", team: "kwpx", imageName: "Taras Zagajewski_Front.jpg", imported: true, status: "active"},
        {name: "Tierney Duffy", team: "kwpx", imageName: "Tierney Duffy_Front.jpg", imported: true, status: "active"},
        {name: "Tony Mcloughlin", team: "kwpx", imageName: "Tony_McLoughlin_Front.jpg", imported: true, status: "active"},
        {name: "Tristan Glover", team: "kwpx", imageName: "Tristan Glover_Front.jpg", imported: true, status: "active"},
        {name: "Vivien Yu", team: "kwpx", imageName: "Vivien Yu_Front.jpg", imported: true, status: "active"},
        {name: "Brenton Bleechmore", team: "kwpx", imageName: ".jpg", imported: false, status: "active"},
        {name: "Maria Russo", team: "kwpx", imageName: ".jpg", imported: false, status: "active"},
    ];




}