<div class="main-div"  [ngClass]="{'flash' : flashing && !this.recipient}">

<div class="styled-select">
    <select #selectElement (change)="selectedUser = selectElement.value" *ngIf="activeUser == 'default'" id='selectedUser' name='selectedUser' >
        <option value="default" selected disabled hidden>Who are you?</option>
        <option *ngFor="let item of users" [ngValue]="item.name">
            {{item.data.name}}
        </option>    
    </select>
</div>
<div *ngIf="activeUser == 'default'" class="button-container" (click)="joinSession()" [ngClass]="{'button-disabled' : selectedUser == 'default' }">
    <button class="button-proceed">Join Session</button>
</div>
<div *ngIf="!timeToRead && !hideButton && activeUser != 'default'">
    <p>It's not your turn. Please wait. It's {{currentReader}}'s turn.</p>
    <div *ngIf="this.activeUser !== 'default' && isUserAdmin() && this.removeUserCount !== 3" class="align-bottom" (click)="removeUserButtonPressed()">
        <button class="button-proceed underline">User is not here</button>
    </div>
</div>
<p *ngIf="!title && !hideButton && timeToRead">You're up, it's your turn to read!</p>
<div *ngIf="!title && !hideButton && timeToRead" class="button-container wide" (click)="fetchAcknowledgement()">
    <button class="button-proceed">Fetch acknowledgement</button>
</div>
<div class="acknowledgement" *ngIf="title">
    <p>Award Name:<br><b>{{title}}</b></p>
    <p>This acknowledgement is for:<br><b>{{recipient}}</b></p>
    <p>{{reason}}</p>    
    <div class="button-container wide" (click)="completeButtonPressed()">
        <button class="button-proceed">I've finished reading</button>
    </div>
    <!-- <p class="fetch-button done-button" ">Done</p> -->
</div>
<div *ngIf="(hideButton && !title && !noAcknowledgement)" class="loader big-loader"></div>
<div class="acknowledgement" *ngIf="noAcknowledgement == true">
    <p>Award Name:<br><b>Thanks</b></p>
    <p>This acknowledgement is for:<br><b>Everyone</b></p>
    <p>For helping out with todays acknowlegements. There are no more acknowlegements to read.</p>    
</div>
</div>