<!-- Screen One -->
<div class="page-wrapper">
    <div class="form-app">
        <div id="screen-1" #screen1 class="form-screen slide">
            <div class="content">
                <div class="content-background col-12">
                    <div class="dn-imprint"></div>
                    <div class="heavy-textbox">
                        <h1>Which <span>{{selectedUser.length >= 1 ? 'legends' : 'legend'}}</span> do you want to give a shout out to?</h1>
                    </div>
                    <div class="tabs">
                        <!-- <div>Digital</div>
                        <div>Brand</div>
                        <div>Media</div>
                        <div>Other</div> -->
                        <div class="form-container">
                            <!-- <form class="textbox"> -->
                                <input class="form-control" placeholder="Search..." [(ngModel)]="search" (ngModelChange)="searchEmployees()">
                            <!-- </form> -->
                        </div>
                        
                    </div>
                    <!--insert foreach loop with array of dn employees-->
                    <div  class="emp-container">
                        <!-- //selectedStates  -->
                        <div class="emp-thumbnail" [ngClass]="{'selected-user' : userSelected(user) || noUsers()}" (click)="userClicked(user)" *ngFor="let user of users">
                            <img src="{{user.data.imageUrl}}" class="emp-headshot"><img/>
                            <!-- employee name here needs to be passed through-->
                            <p>{{user.data.name}}</p>
                        </div> 
                    </div>
                    <!-- On proceed slides up -->
                    <!-- <div (click)="stepOneButtonPressed()" class="button-container" [ngClass]="{'button-live' : selectedUser }">
                        <a href="#screen-2"><button class="button-proceed">Continue</button></a>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- Screen Two : hidden currently will slide down from top -->
        <div id="screen-2" #screen2 *ngIf="selectedUser" class="form-screen slide">
            <div class="content">
                <div class="content-background col-12">
                    <div class="dn-imprint"></div>
                    <div class="heavy-textbox">
                        <h1 *ngIf="selectedUser[0] && selectedUser[0].data.name === ' Christmas Joke'">Go on, what's the first part of your joke?</h1>
                        <h1 *ngIf="selectedUser[0] && selectedUser[0].data.name !== ' Christmas Joke'">What is the award name for {{selectedUser.length >= 2 ? 'these' : 'this'}} <span>{{selectedAdjective}}</span> {{getHumanString()}}?</h1>
                    </div>
                    <div class="form-container">
                        <!-- <form class="textbox"> -->
                            <input class="form-control" placeholder="Be creative..." [(ngModel)]="what">
                        <!-- </form> -->
                    </div>
                    <div class="button-container" (click)="stepTwoButtonPressed()" [ngClass]="{'button-live' : what }">
                        <button class="button-proceed">Continue</button>
                    </div>
                    <div class="center-div">
                    <h3>OR</h3> 
                    </div>
                    <div class="button-container-small button-live" (click)="navigateToStepOne()">
                        <button *ngIf="selectedUser[0] && selectedUser[0].data.name === ' Christmas Joke'" class="button-proceed">Previous step</button>
                        <button *ngIf="selectedUser[0] && selectedUser[0].data.name !== ' Christmas Joke'" class="button-proceed">Add another person</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Screen Three -->
        <div id="screen-3" #screen3 *ngIf="selectedUser && what" class="form-screen slide">
            <div class="content">
                <div class="content-background col-12">
                    <div class="dn-imprint"></div>
                    <div class="heavy-textbox">
                        <h1 *ngIf="selectedUser[0] && selectedUser[0].data.name === ' Christmas Joke'" >What's the answer to your joke?</h1>
                        <h1 *ngIf="selectedUser[0] && selectedUser[0].data.name !== ' Christmas Joke'" >Why are you giving <span (click)="selectedUsersName()">{{this.formattedName}}</span><span><img (click)="selectedUsersName()" *ngIf="selectedUser.length <= 1" class="reload-logo" src="assets/reload.png" alt=""></span> this award?</h1>
                    </div>
                    <div class="form-container">
                        <!-- <form class="textbox"> -->
                            <textarea row="5" class="form-control" placeholder="Be descriptive..." [(ngModel)]="why"></textarea>
                        <!-- </form> -->
                    </div>
                    <div (click)="submitAcknowledgement(); loading = true;" class="submit-button-container" [ngClass]="{'button-live' : why }">
                        <div *ngIf="loading" class="loader big-loader"></div>
                        <button *ngIf="!loading" class="button-submit">Shout it Out!</button>
                    </div>
                    <div class="center-div">
                    <h3>OR</h3> 
                    </div>
                    <div class="button-container-small button-live" (click)="stepOneButtonPressed()">
                        <button class="button-proceed">Previous Step</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- navigation bar -->
</div>
<div class="nav-bar-bottom">
        <div class="nav-link">
            <a routerLink="/form"><img class="tab-selected" src="assets/bullhorn.svg"></a>
        </div>
        <div class="nav-link">
            <a (click)="readShoutButtonPressed()"><img src="assets/heart.svg"></a>
        </div>
        <div class="nav-link">
            <a routerLink="/history"><img src="assets/history.svg"></a>
        </div>
</div>